import React, { useState, useEffect, useCallback, useRef } from "react";
import Table from "components/Table/Table";
import { SystemTableData } from "./SystemTableData";
import { useAppSelector } from "../../redux/hooks";
import {
    getSystemList,
    checkSystemInUse,
    checkXMLImportInProgress,
    deleteSystem,
    onAddSystem,
    onEditSystem,
    onInspectionTaskClose,
    getSystemSearchDetailsList,
    onUploadSystemXml,
    getEquipmentGroups,
    activateSystem,
    closeSystem,
    checkDownloadFile,
    getClosedTasksOutcomes,
    downloadProvenOutcomes,
    getUploadedXMLFileList,
    setXMLImportInProgress,
    setUploadedXmlFileList,
    deleteXMLFile,
    checkSystemforClose,
    setCheckSystemforClose,
    reactivateInspectionTask,
    getUnlinkedEquipmentGroups,
    getSystemEquipmentGroups,
    setSystemList,
    setSystemPageDetails
} from "redux/pages/Inspection/Systems/systemSlice";
import { useDispatch } from "react-redux";

import AddSystems from "./ManageSystem/AddSystem";
import EditSystem from "./ManageSystem/EditSystem";
import UploadXML from "./ManageSystem/UploadXML";
import { OverlayPanel } from "primereact/overlaypanel";
import PopOver from "components/common/PopOver/PopOver";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import MasterData from "./MasterData/MasterData";
import { Dialog } from "primereact/dialog";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import EquipmentGroupList from "./EquipmentGroups/EquipmentGroupList";
import { BsExclamationCircleFill } from "react-icons/bs";
import { showUI } from 'service/GeneralUtility';
import SystemsReport from "pages/Systems/Report/SyestemsReport";
import UnlinkedEquipmentGroups from "./UnlinkedEquipmentGroups/UnlinkedEquipmentGroups";
import { fetchLoggedInUserDetails } from "../../redux/pages/Administration/administrationSlice";
import SystemEquipmentGroupList from "./SystemEquipmentGroups/SystemEquipmentGroupsList";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface Props { }

type FormData = {
    uniqueinspectiontaskid: string;
};

export const Systems: React.FC<Props> = () => {
    const systemList = useAppSelector((state) => state.system.systemReviseList);
    const systemPageDetails = useAppSelector((state) => state.system.systemPageDetails);
    const XMLImportInProgress = useAppSelector((state) => state.system.XMLImportInProgress);
    const systemSearchId = useAppSelector((state) => state.system.systemSearchId);
    const systemInUse = useAppSelector((state) => state.system.systemInUse);
    const provenOutcomesData = useAppSelector((state) => state.system.provenOutcomesData);
    const uploadedXmlFileList = useAppSelector((state) => state.system.uploadedXmlFileList);
    const isSystemforClose = useAppSelector((state) => state.system.isSystemforClose);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const newSystemDetails = useAppSelector((state) => state.system.newSystemDetails);
    const xmlUploadedSystemData = useAppSelector((state) => state.system.xmlUploadedSystemData);
    const unlinkedEquipmentGroups = useAppSelector((state) => state.system.unlinkedEquipmentGroups);
    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

    const [pageDetails, setPageDetails] = useState<any>({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [filterExpanded] = useState<any>(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showMasterData, setShowMasterData] = useState(false);
    const [showProvenOutcomesModal, setShowProvenOutcomesModal] = useState(false);

    const [showUploadXMLModal, setShowUploadXMLModal] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);

    const [selectedSystemId, setSelectedSystemId] = useState("")
    const [systemRowData, setSystemRowData] = useState<any>("");
    const [tableColumns, setTableColumns] = useState<Array<any>>([]);
    const [message, setMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isRowEdit, setIsRowEdit] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isClose, setIsClose] = useState(false);
    const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [keyWord, setKeyWord] = useState("");
    const [equipmentGroups, setEquipmentGroups] = useState([]);
    const [showEquipmentGroupModal, setShowEquipmentGroupModal] = useState(false);
    const [acceptedFileTypes, setAcceptedFileTypes] = useState<any>(["xml"])
    const [isConfig, setIsConfig] = useState(false);
    const [isSystemAdd, setIsSystemAdd] = useState(false);
    const [isReactivate, setIsReactivate] = useState(false);
    const [isReactivateOnFileUpload, setIsReactivateOnFileUpload] = useState(false);
    const [uniqueInspectionTaskId, setUniqueInspectionaskId] = useState<any>("");
    const [showReportModal, setShowReportModal] = useState(false);
    const [settingListItems, setSettingListItems] = useState<any>()
    const [reportFlag, setReportFlag] = useState<any>('all');
    const [uploadErrorMsg, setUploadErrorMsg] = useState('Select an XML file.');
    const [uploadTitle, setUploadTitle] = useState('Upload XML File');
    const [allowRowEditing, setAllowRowEditing] = useState<boolean>(true);
    const [isUnlinkedEquipmentGroup, setIsUnlinkedEquipmentGroup] = useState<boolean>(false);
    const [showUnlinkedEquipmentGroup, setShowUnlinkedEquipmentGroup] = useState<boolean>(false);
    const [defaultSiteId, setDefaultSiteId] = useState<any>("");
    const [defaultSiteName, setDefaultSiteName] = useState<any>("");
    const [isSystemEquipmentGroup, setIsSystemEquipmentGroup] = useState<boolean>(true);
    const [showSystemEquipmentGroups, setShowSystemEquipmentGroups] = useState<boolean>(false);

    const deleteRef = React.createRef<OverlayPanel>();

    const attachmentRef = React.createRef<OverlayPanel>();
    const downloadAttachmentRef = React.createRef<OverlayPanel>();

    const dispatch = useDispatch<any>();
    const outcomesDownloadForm: any = useRef();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset
    } = useForm<FormData>({
        defaultValues: {
            uniqueinspectiontaskid: "",
        }
    });

    useEffect(() => {
        if (userPrivileges && userPrivileges.length) {
            filterSettingItemList();
        }
    }, [userPrivileges, selectedRows])

    const filterSettingItemList = () => {
        const settingList: any = [];
        userPrivileges.map(element => {
            if (element === 'SIETAP_SYSTEMS_ACTIVATE') {
                settingList.push({
                    id: 2,
                    name: "Activate",
                    onClick: () => onActivateClick(selectedRows)
                })
            } else if (element === 'SIETAP_SYSTEMS_CLOSE') {
                settingList.push({
                    id: 1,
                    name: "Close",
                    onClick: () => onCloseClick(selectedRows)
                })
            } else if (element === 'SIETAP_SYSTEMS_EQUIPMENTGROUPS') {
                settingList.push({
                    id: 3,
                    name: "Equipment Groups",
                    onClick: () => equipmentGroupsHandler(selectedRows),
                })
            } else if (element === 'SIETAP_SYSTEMS_MASTERDATA') {
                settingList.push({
                    id: 4,
                    name: "Master Data",
                    onClick: () => onMasterDataClick(selectedRows)

                })
            } else if (element === 'SIETAP_SYSTEMS_PROVENOUTCOMES') {
                settingList.push({
                    id: 5,
                    name: "Proven Outcomes",
                    onClick: () => {
                        onProvenOutcomesClick(selectedRows);
                    },
                })
            }
        });
        settingList.sort((a, b) => b.id > a.id ? -1 : 1);
        setSettingListItems(settingList)
    }
    useEffect(() => {
        if (systemSearchId !== "") {
            setIsSearch(true);
            setSelectedRows([]);
            dispatch(getSystemSearchDetailsList(systemSearchId));
        } else if (isSearch === false) {
            dispatch(getSystemList(1, keyWord, sortField, sortOrder));
        }

        return(() => {
            dispatch(setSystemList([]));
            dispatch(setSystemPageDetails([]));
        });
    }, [dispatch, systemSearchId, isSearch,rowsPerPage]);

    useEffect(() => {
        if (systemPageDetails) {
          setPageDetails(systemPageDetails);
        }
      }, [systemPageDetails])
    
      useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
          let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
          setPageDetails(modifiedPageDetails);
          setCurrentStart(1)
          setPageIndex(0)
        }
      }, [rowsPerPage])

   useEffect(()=>{
    if(systemList && systemList?.length && selectedRows?.length){
        systemList.map((systemListItem)=>{
            if(systemListItem.uniqueSystemId === selectedRows[0].uniqueSystemId) {
               selectedRows[0] = systemListItem;
            }
        })
    }
   },[systemList])


    useEffect(() => {
        if (isDelete && Object.keys(XMLImportInProgress).length !== 0) {
            if (XMLImportInProgress.isSystemParseInProgress === true) {
                setMessage("System is in use, cannot be deleted");
                setDisplayInfoModal(true);
            } else {
                setMessage("All the Inspection Task details related to this System will be deleted, are you sure you want to continue?");
                setDisplayConfirmModal(true);
            }
            dispatch(setXMLImportInProgress([]))
        }
        if (isEdit && Object.keys(XMLImportInProgress).length !== 0) {
            if (XMLImportInProgress.isSystemParseInProgress === true) {
                setMessage("XML importing is in progress, cannot be edited");
                setDisplayInfoModal(true);
                setIsEdit(false);
            } else if (selectedRows[0].activeTask) {
                setMessage("System cannot be edited as Active Task exists for the selected System. Do you want to close the Active Task?");
                setDisplayConfirmModal(true);
            } else {
                setShowEditModal(true);
                setIsEdit(false);
            }
            dispatch(setXMLImportInProgress([]))
        }
        if (isRowEdit && Object.keys(XMLImportInProgress).length !== 0) {
            if (XMLImportInProgress.isSystemParseInProgress === true) {
                setMessage("XML importing is in progress, cannot be edited");
                setDisplayInfoModal(true);
                setIsRowEdit(false);
                setAllowRowEditing(false)
            } else if (systemRowData.activeTask) {
                setMessage("System cannot be edited as Active Task exists for the selected System. Do you want to close the Active Task?");
                setDisplayConfirmModal(true);
                setAllowRowEditing(false)
            } else {
                setAllowRowEditing(true);
                setIsRowEdit(false);
            }
            dispatch(setXMLImportInProgress([]))
        }
        if (isFileUpload && Object.keys(XMLImportInProgress).length !== 0) {
            if (XMLImportInProgress.isSystemParseInProgress === true) {
                setMessage("XML importing is in progress, XML file cannot be uploaded");
                setDisplayInfoModal(true);
                setIsFileUpload(false);
            } else if (selectedRows[0].activeTask) {
                setMessage("As Active Tasks exist, XML file cannot be uploaded to the selected System. Do you want to close the Active Task?");
                setDisplayConfirmModal(true);
            } else {
                setAcceptedFileTypes(["xml"])
                setUploadErrorMsg("Select an XML File");
                setUploadTitle("Upload XML File");
                dispatch(getUploadedXMLFileList(selectedRows[0].uniqueSystemId, false))
                setShowUploadXMLModal(true);
                setIsFileUpload(false);
            }

            dispatch(setXMLImportInProgress([]))
        }
    }, [dispatch, systemInUse, isDelete, isEdit, isFileUpload, XMLImportInProgress]);

    useEffect(() => {
        if (selectedRows && selectedRows.length) {
            dispatch(checkSystemInUse(selectedRows[0]?.uniqueSystemId));
        }
    }, [selectedRows]);

    useEffect(() => {
        if (isSystemforClose) {
            if (!isSystemforClose.isSystemParsed) {
                setIsClose(false);
                setMessage('XML importing is in progress, cannot be closed')
                setDisplayInfoModal(true);
            } else if (isSystemforClose.isActiveTaskExists) {
                if (isSystemforClose.techniciansName && isSystemforClose.techniciansName.length) {
                    setIsClose(true);
                    let confirmationMessage = `Do you want to quit all the tasks of the Technician(s) and close the Inspection Task and System?`;
                    let technicians = '';
                    isSystemforClose.techniciansName.map((item, currentIndex) => {
                        if (item && (item.trim() !== '')) {
                            if (currentIndex === 0) {
                                technicians += item;
                            } else {
                                technicians += " ," + item;
                            }
                        }
                    });
                    if (technicians !== '') {
                        confirmationMessage += ` Technicians connected to the Inspection Task - ${technicians}`;
                    }
                    setMessage(confirmationMessage)
                    setDisplayConfirmModal(true);
                } else {
                    setIsClose(true);
                    setMessage(`Are you sure you want to close the selected System and it's Inspection Task?`)
                    setDisplayConfirmModal(true);
                }
            } else if (isSystemforClose.techniciansName && isSystemforClose.techniciansName.length) {
                setIsClose(true);
                let confirmationMessage = `Do you want to quit all the tasks of the Technician(s) and close the Inspection Task and System?`;
                let technicians = '';
                isSystemforClose.techniciansName.map((item, currentIndex) => {
                    if (item && (item.trim() !== '')) {
                        if (currentIndex === 0) {
                            technicians += item;
                        } else {
                            technicians += " ," + item;
                        }
                    }
                });
                if (technicians !== '') {
                    confirmationMessage += ` Technicians connected to the Inspection Task - ${technicians}`;
                }
                setMessage(confirmationMessage)
                setDisplayConfirmModal(true);
            } else {
                setIsClose(true);
                setMessage(`Are you sure you want to close the selected System?`);
                setDisplayConfirmModal(true);
            }
            dispatch(setCheckSystemforClose(""))
        }
    }, [isSystemforClose])

    useEffect(() => {
        if (provenOutcomesData && provenOutcomesData.length) {
            if (provenOutcomesData.length === 1) {
                setValue('uniqueinspectiontaskid', provenOutcomesData[0]?.uniqueInspectionTaskId);
            }
        }
    }, [provenOutcomesData]);

    const onAddSystemClick = (submitData: any) => {
        setIsSystemAdd(true)
        dispatch(
            onAddSystem(
                submitData,
                setMessage,
                setDisplayInfoModal,
                setDisplayConfirmModal,
                setShowAddModal,
                setIsAdd,
            ))
    }

    useEffect(() => {
        if (newSystemDetails && isSystemAdd) {
            setSelectedRows([newSystemDetails])
            setSelectedSystemId(newSystemDetails.uniqueSystemId);
            setIsSystemAdd(false)
        }
    }, [newSystemDetails])

    useEffect(() => {
        if (xmlUploadedSystemData) {
            setSelectedRows([xmlUploadedSystemData])
            setSelectedSystemId(xmlUploadedSystemData.uniqueSystemId);
            setIsSystemAdd(false)
        }
    }, [xmlUploadedSystemData])

    const onPageChanged = (options: any) => {
        if (options !== pageIndex) {
            let currentStart = options * pageDetails?.currentLength + 1;
            setCurrentStart(currentStart);
            setPageIndex(options);
            dispatch(getSystemList(currentStart, keyWord, sortField, sortOrder));
        }
    };

    const onGlobalSearch = (filter) => {
        setKeyWord(filter);
        dispatch(getSystemList(1, filter, sortField, sortOrder));
    };

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1) sortDirection = "ASC";
        else sortDirection = "DESC";
        setSortField(event.sortField);
        setSortOrder(sortDirection);
        dispatch(getSystemList(currentStart, keyWord, event.sortField, sortDirection));
    };

    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data]);
    }, []);


    const deleteSystemClick = () => {
        setIsAdd(false)
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length === 1) {
            if (selectedRows[0].status === "Active") {
                setMessage("Selected System is Active, cannot be deleted");
                setDisplayInfoModal(true);
            } else {
                setIsDelete(true);
                dispatch(checkXMLImportInProgress(selectedRows[0].uniqueSystemId));
            }
        }
    };

    const OnConfirmClick = () => {
        if (isDelete) {
            setDisplayConfirmModal(false);
            if (selectedRows.length === 1) {
                const uniqueSystemId = selectedRows[0].uniqueSystemId;
                dispatch(
                    deleteSystem({
                        uniqueSystemId,
                        setSelectedRows,
                        setMessage,
                        setDisplayInfoModal,
                    })
                );
            }
            setIsDelete(false);
        } else if (isEdit) {
            setDisplayConfirmModal(false);
            const uniqueSystemId = selectedRows[0].uniqueSystemId;
            dispatch(
                onInspectionTaskClose(
                    uniqueSystemId,
                    setMessage,
                    setDisplayInfoModal,
                    setShowEditModal,
                    setShowUploadXMLModal,
                    isEdit,
                    selectedRows[0],
                    setIsReactivate,
                    setUniqueInspectionaskId
                )
            );
            setIsEdit(false)
        } else if(isFileUpload){
            setDisplayConfirmModal(false);
            const uniqueSystemId = selectedRows[0].uniqueSystemId;
            dispatch(
                onInspectionTaskClose(
                    uniqueSystemId,
                    setMessage,
                    setDisplayInfoModal,
                    setShowEditModal,
                    setShowUploadXMLModal,
                    isEdit,
                    selectedRows[0],
                    setIsReactivate,
                    setUniqueInspectionaskId
                )
            );
            setIsReactivateOnFileUpload(false);
            setIsFileUpload(false);
        } else if (isRowEdit) {       
            setDisplayConfirmModal(false);  
            setAllowRowEditing(false)   
            const uniqueSystemId = systemRowData.uniqueSystemId;
            setSelectedRows([systemRowData])
            dispatch(
                onInspectionTaskClose(
                    uniqueSystemId,
                    setMessage,
                    setDisplayInfoModal,
                    setShowEditModal,
                    setShowUploadXMLModal,
                    isRowEdit,
                    systemRowData,
                    setIsReactivate,
                    setUniqueInspectionaskId
                )
            );
            setIsRowEdit(false);
        } else if (isAdd) {
            setDisplayConfirmModal(false);
            setShowUploadXMLModal(true);
            setIsAdd(false);
        } else if (isClose) {
            setDisplayConfirmModal(false);
            dispatch(closeSystem(selectedRows[0], setSelectedRows, setMessage, setDisplayInfoModal));
            setIsClose(false);
        } else if (isReactivate) {
            setDisplayConfirmModal(false);
            dispatch(reactivateInspectionTask(uniqueInspectionTaskId, setMessage, setDisplayInfoModal, selectedRows[0]));
            setIsReactivate(false);
        } else if (isReactivateOnFileUpload) {
            setDisplayConfirmModal(false);
            dispatch(reactivateInspectionTask(uniqueInspectionTaskId, setMessage, setDisplayInfoModal, selectedRows[0]));
            setIsReactivateOnFileUpload(false);
        }
    }

    const onCancelClick = () => {
        setIsFileUpload(false);
        setIsEdit(false);
        setIsReactivate(false);
        setIsReactivateOnFileUpload(false);
        if (isAdd) {
            setIsAdd(false);
            setShowAddModal(false);
        }
    }


    const editIconClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            switch (selectedRows[0].status) {
                case "Closed":
                    setMessage("Selected System is Closed, cannot be edited");
                    setDisplayInfoModal(true);
                    break;
                default:
                    dispatch(checkXMLImportInProgress(selectedRows[0].uniqueSystemId));
                    setIsEdit(true);
                    break;
            }
        }
    };

    const onEditSystemClick = (submitData: any) => {

        let uniqueSystemId: any;
        if (selectedRows.length === 0) uniqueSystemId = submitData.uniqueSystemId;
        else uniqueSystemId = selectedRows[0].uniqueSystemId;
        dispatch(onEditSystem(submitData, uniqueSystemId, setMessage, setDisplayInfoModal, setShowEditModal, selectedRows[0], setIsEdit));
    };
    const onEditSystemRowClick = (submitData: any) => {
        let uniqueSystemId: any;
        uniqueSystemId = submitData.uniqueSystemId;
        let isAlternate = submitData.preferredLanguage === 'Alternate' ? true : false
        let isConfigFile = submitData.configFile === 'Yes' ? true : false
        let isXmlFile = submitData.xmlFile === 'Yes' ? true : false
        let isNetworked = submitData.isNetworked === 'True' ? true : false
        let body = {
            activeTask: "",
            configFile: isConfigFile,
            isAlternateLanguage: isAlternate,
            isNetworked: isNetworked,
            status: submitData.status,
            systemName: submitData.systemName,
            systemType: submitData.systemType,
            xmlFile: isXmlFile,
            activeSiteId : loggedInUserDetails?.userDefaults?.uniqueSiteId
        }
        dispatch(onEditSystem(body, uniqueSystemId, setMessage, setDisplayInfoModal, setShowEditModal, systemRowData, setIsRowEdit));
    };

    const onUploadXMLFileClick = () => {
        dispatch(setUploadedXmlFileList(""))
        setIsConfig(false);
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            if (
                selectedRows[0].systemType === "MXL" ||
                selectedRows[0].systemType === "XLS" ||
                selectedRows[0].systemType === "Desigo FS20" ||
                selectedRows[0].systemType === "Desigo FV20" ||
                selectedRows[0].systemType === "Desigo Modular" ||
                selectedRows[0].systemType === "Cerberus PRO FC92" ||
                selectedRows[0].systemType === "Cerberus PRO FV92" ||
                selectedRows[0].systemType === "Cerberus PRO Modular"
            ) {
                if(selectedRows[0].status === "Closed") {
                    setMessage("Selected System is Closed, cannot upload the XML file");
                    setDisplayInfoModal(true);
                } else {
                dispatch(checkXMLImportInProgress(selectedRows[0].uniqueSystemId));
                setSelectedSystemId(selectedRows[0].uniqueSystemId);
                setIsFileUpload(true);
                }
            } else {
                setMessage(
                    "System Type of selected System is not MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular/Cerberus PRO FC92/Cerberus PRO FV92/Cerberus PRO Modular, cannot upload the XML file"
                );
                setDisplayInfoModal(true);
            }
        }
    };

    const onUploadXml = (data: any) => {
        dispatch(onUploadSystemXml(selectedSystemId,loggedInUserDetails?.userDefaults?.uniqueSiteId, data, setShowUploadXMLModal, setMessage, setDisplayInfoModal, isConfig, selectedRows[0], setSelectedRows, isReactivate, setIsReactivateOnFileUpload));
        setIsAdd(false);
        setShowAddModal(false);
        setIsFileUpload(false);
    }

    const onUploadConfigFileClick = () => {
        dispatch(setUploadedXmlFileList(""))
        setIsConfig(true);
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            if (
                selectedRows[0].systemType === "MXL" ||
                selectedRows[0].systemType === "XLS" ||
                selectedRows[0].systemType === "Desigo FS20" ||
                selectedRows[0].systemType === "Desigo FV20" ||
                selectedRows[0].systemType === "Desigo Modular" ||
                selectedRows[0].systemType === "Cerberus PRO FC92" ||
                selectedRows[0].systemType === "Cerberus PRO FV92" ||
                selectedRows[0].systemType === "Cerberus PRO Modular"
            ) {             
                if(selectedRows[0].status === "Closed") {
                    setMessage("Selected System is Closed, cannot upload the Configuration file");
                    setDisplayInfoModal(true);
                }else {
                    setSelectedSystemId(selectedRows[0].uniqueSystemId);
                    setAcceptedFileTypes(["zip","7z"])
                    setUploadErrorMsg("Select a Zip File or 7-Zip File");
                    setUploadTitle("Upload Config File")
                    dispatch(getUploadedXMLFileList(selectedRows[0].uniqueSystemId, true))
                    setShowUploadXMLModal(true);
                }
            } else {
                setMessage(
                    "System Type of selected System is not MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular/Cerberus PRO FC92/Cerberus PRO FV92/Cerberus PRO Modular, cannot upload the Configuration file"
                );
                setDisplayInfoModal(true);
            }
        }
    };

    const onDeleteFileClick = (data: any) => {
        if (selectedRows && selectedRows.length) {
            dispatch(deleteXMLFile(selectedRows[0]?.uniqueSystemId, data?.fileId, isConfig,loggedInUserDetails?.userDefaults?.uniqueSiteId, setMessage, setDisplayInfoModal, setShowUploadXMLModal, selectedRows[0]));        }
    }


    const equipmentGroupsHandler = (selectedRows) => {
        if (selectedRows.length < 1) {
            setMessage("Select an Inspection Task");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            if (selectedRows[0]?.status === "Active") {
                dispatch(getEquipmentGroups(selectedRows[0]?.uniqueSystemId, setEquipmentGroups, setMessage, setDisplayInfoModal, setShowEquipmentGroupModal));
            } else if (selectedRows[0]?.status === "Closed") {
                setMessage("Selected System is Closed, cannot update equipment group details");
                setDisplayInfoModal(true);
            } else if (selectedRows.length > 1) {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            } else {
                if (selectedRows[0]?.status === "Active") {
                    dispatch(getEquipmentGroups(selectedRows[0]?.uniqueSystemId, setEquipmentGroups, setMessage, setDisplayInfoModal, setShowEquipmentGroupModal));
                } else if (selectedRows[0]?.status === "Closed") {
                    setMessage("Selected System is Closed, cannot update equipment group details");
                    setDisplayInfoModal(true);
                } else if (selectedRows[0]?.status === "Blocked") {
                    setMessage("Selected System is Blocked, cannot update equipment group details");
                    setDisplayInfoModal(true);
                }
            }
        }
    }


    const onDownloadXMLFileClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            const isConfig: boolean = false;
            dispatch(checkDownloadFile(selectedRows[0].uniqueSystemId, isConfig, setMessage, setDisplayInfoModal));
            downloadAttachmentRef.current?.hide();
        }
    };

    const onDownloadConfigFileClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            const isConfig: boolean = true;
            dispatch(checkDownloadFile(selectedRows[0].uniqueSystemId, isConfig, setMessage, setDisplayInfoModal));
            downloadAttachmentRef.current?.hide();
        }
    };

    const onProvenOutcomesClick = (selectedRows) => {
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            if (selectedRows[0]?.activeTask !== "") {
                const uniqueSystemId = selectedRows[0].uniqueSystemId;
                dispatch(
                    getClosedTasksOutcomes(
                        uniqueSystemId,
                        setMessage,
                        setDisplayInfoModal,
                        setShowProvenOutcomesModal,
                    ))
            } else {
                setDisplayInfoModal(true);
                setMessage("No Tasks exist for the selected System");
            }
        }
    };

    const onOutcomesDownloadFormSubmit = (data: any) => {
        if (selectedRows && selectedRows[0].uniqueSystemId) {
            const uniqueSystemId = selectedRows[0].uniqueSystemId;
            dispatch(downloadProvenOutcomes(uniqueSystemId, data, setShowProvenOutcomesModal));
        }
    };

    const onActivateClick = (selectedRows) => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length === 1) {
                if (selectedRows[0]?.status && (selectedRows[0]?.status === 'Active')) {
                    setMessage('System is already Active');
                    setDisplayInfoModal(true);
                } else {
                    dispatch(activateSystem(selectedRows[0], setSelectedRows, setMessage, setDisplayInfoModal));
                }
            } else {
                setMessage('This operation can be performed only one row at a time');
                setDisplayInfoModal(true);
            }
        } else {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        }
    }
    const onCloseClick = (selectedRows) => {
        if (selectedRows?.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        }
        else {
            setIsAdd(false)
            if (selectedRows.length === 1) {
                if (selectedRows[0]?.status && (selectedRows[0]?.status === 'Closed')) {
                    setIsClose(false);
                    setMessage('System is already closed');
                    setDisplayInfoModal(true);
                } else {
                    dispatch(checkSystemforClose(selectedRows[0]?.uniqueSystemId));
                }
            } else {
                setIsClose(false);
                setMessage('This operation can be performed only one row at a time');
                setDisplayInfoModal(true);
            }

        }
    }
    const onMasterDataClick = (selectedRows) => {
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            setShowMasterData(true);
        }
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const onOkClick = () => {
        setDisplayInfoModal(false);
        if (isReactivate && isEdit) {
            setIsReactivate(true);
            setIsEdit(false);
            setMessage("Do you want to re-activate currently closed task of the System?");
            setDisplayConfirmModal(true);
        } else if (isReactivate && isReactivateOnFileUpload) {
            setIsReactivate(false);
            setMessage("Do you want to re-activate currently closed task of the System?");
            setDisplayConfirmModal(true);
        }
        setIsEdit(false);
        setAllowRowEditing(true)
    }

    const onReportAllClick = () => {
        setReportFlag('all');
        setShowReportModal(true);
    };

    const onReportHighlightedClick = () => {
        setReportFlag('highlighted');
        setShowReportModal(true);
    };

    const getSelectedSystemsId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueSystemId);
        });
        return params;
    };

    const onExportAll = () => {
		const reportParameters = {
            exportParameters: {
                uniqueSystemId: "",
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: keyWord
            },
            exportType: "System Grid Report"
        };
		dispatch(onExportReport(reportParameters, "Systems"));
	};

	const onExportHighlightedClick = () => {
		const reportParameters = {
            exportParameters: {
                uniqueSystemId: getSelectedSystemsId(),
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: keyWord
            },
            exportType: "System Grid Report"
        };
		dispatch(onExportReport(reportParameters, "Systems"));
	};

    const handleTableColumns = useCallback((data: any) => {
        setTableColumns([...data]);
    }, []);

    const onRowEditClick = (rowData: any) => {
        let isEditable;
        if (rowData.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else {
            switch (rowData.status) {
                case "Closed":
                    setMessage("Selected System is Closed, cannot be edited");
                    setDisplayInfoModal(true);
                    break;
                default:
                    dispatch(checkXMLImportInProgress(rowData.uniqueSystemId));
                    setIsRowEdit(true);
                    isEditable = true;
                    setSystemRowData(rowData)
                    break;
            }
        }


        return isEditable
    }

    //handle form validation errors
    const onError = (errors: any, e: any) => { };

    const onUnlinkedEquipmentGroupClick = () => {
        setIsUnlinkedEquipmentGroup(true);
        dispatch(fetchLoggedInUserDetails());
    }

    useEffect(() => {
        if (unlinkedEquipmentGroups && unlinkedEquipmentGroups.length > 0 && unlinkedEquipmentGroups !== undefined) {
            setShowUnlinkedEquipmentGroup(true);
        }
    }, [dispatch, unlinkedEquipmentGroups])

    useEffect(() => {
        if (loggedInUserData && loggedInUserData !== undefined && loggedInUserData !== "" && loggedInUserData?.userDefaults?.uniqueSiteId) {
            if (isUnlinkedEquipmentGroup === true) {
                setDefaultSiteId(loggedInUserData?.userDefaults?.uniqueSiteId);
                setDefaultSiteName(loggedInUserData?.userDefaults?.siteName);
                dispatch(getUnlinkedEquipmentGroups(loggedInUserData?.userDefaults?.uniqueSiteId, setDisplayInfoModal, setMessage));
            }
        }
    }, [dispatch, loggedInUserData])

    const onSystemEquipmentGroupClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        } else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows.length = 1) {
            setIsSystemEquipmentGroup(true);
            dispatch(getSystemEquipmentGroups(selectedRows[0]?.uniqueSystemId, setDisplayInfoModal, setMessage, setShowSystemEquipmentGroups));
        }
    }

    return (
        <div className="systems-container">
            <SystemTableData getTableColumns={handleTableColumns} selectedRow={systemRowData} />
            <section className={`${filterExpanded ? "expanded" : ""} system-table`}>
                <Table
                    showDeleteDownArrow
                    rows={systemList}
                    cols={tableColumns}
                    title="Systems"
                    dataKeyId="uniqueSystemId"
                    paginator
                    showAttachIcon={showUI(userPrivileges, "SIETAP_SYSTEMS_UPLOADXML") || showUI(userPrivileges, "SIETAP_SYSTEMS_UPLOADCONFIG")}
                    onAttachmentClick={(e) => attachmentRef.current?.toggle(e)}
                    onDownloadClick={(e) => downloadAttachmentRef.current?.toggle(e)}
                    pageDetails={pageDetails}
                    onPageChanged={onPageChanged}
                    pageIndex={pageIndex}
                    getSelectedRowData={handleRowSelect}
                    onAddClick={() => setShowAddModal(true)}
                    onEditClick={editIconClick}
                    searchText="Enter System Name"
                    onDeleteClick={(e) => deleteRef.current?.toggle(e)}
                    onGlobalSearch={onGlobalSearch}
                    onSortData={onSort}
                    onSaveRowEdit={onEditSystemRowClick}
                    onRowEditClick={onRowEditClick}
                    showroweditor={showUI(userPrivileges, 'SIETAP_FS_SYSTEMS_MANAGE')}
                    showAddIcon={showUI(userPrivileges, 'SIETAP_FS_SYSTEMS_MANAGE')}
                    showEditIcon={showUI(userPrivileges, 'SIETAP_FS_SYSTEMS_MANAGE')}
                    showDeleteIcon={showUI(userPrivileges, 'SIETAP_FS_SYSTEMS_MANAGE')}
                    defaultSelectedRows={selectedRows}
                    settingListItems={settingListItems}
                    allowRowEditing={allowRowEditing}
                />
            </section>
            <PopOver ref={deleteRef}>
                <ul>
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_DELETE") && <li onClick={deleteSystemClick}>System</li>}
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_DELETE_EQUIPMENTGROUPS") && <li onClick={onSystemEquipmentGroupClick}>System Equipment Groups</li>}
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_DELETE_UNLINKED_EQUIPMENTGROUPS") && <li onClick={onUnlinkedEquipmentGroupClick}>Unlinked Equipment Groups</li>}
                </ul>
            </PopOver>
            {showAddModal && <AddSystems showModal={showAddModal} setShowModal={setShowAddModal} onAddSystemClick={(data) => onAddSystemClick(data)} />}
            {showMasterData && <MasterData showModal={showMasterData} setShowModal={setShowMasterData} selectedRows={selectedRows} />}

            {showProvenOutcomesModal && (
                <Dialog
                    header="Proven Outcomes Export"
                    visible={showProvenOutcomesModal}
                    style={{ width: "25vw" }}
                    onHide={() => {
                        reset();
                        setShowProvenOutcomesModal(false)
                    }}
                    className="display-settings selectsite Proven-Outcomes"
                >
                    <form onSubmit={handleSubmit(onOutcomesDownloadFormSubmit, onError)} ref={outcomesDownloadForm}>
                        <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                                {provenOutcomesData && provenOutcomesData.length &&
                                    <Controller
                                        name="uniqueinspectiontaskid"
                                        control={control}
                                        rules={{ required: "Select an Inspection Task" }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="dropdown"
                                                className={classNames("w-100 error-tooltip", {
                                                    error: fieldState.invalid,
                                                })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.value)}
                                                options={provenOutcomesData}
                                                optionLabel="inspectionTaskName"
                                                optionValue="uniqueInspectionTaskId"
                                            />
                                        )}
                                    />}
                                <label className="mandatory" htmlFor="dropdown">
                                    Inspection Task
                                </label>
                                {getFormErrorMessage("uniqueinspectiontaskid")}
                            </span>
                        </div>
                        <div className="field col-12 md:col-4 footer-col pb-3">
                            <Button label="Download" type="submit" className='submit-btn w-100' autoFocus />
                        </div>
                    </form>
                </Dialog>
            )}

            {showUploadXMLModal && <UploadXML showModal={showUploadXMLModal} setShowModal={setShowUploadXMLModal} onUploadXmlClick={onUploadXml} uploadedFileList={uploadedXmlFileList} acceptedFileTypes={acceptedFileTypes} onDeleteFileClick={onDeleteFileClick} errorMessage={uploadErrorMsg} title={uploadTitle} typeFlag={isConfig ? "Config" : "XML"}/>}
            <PopOver ref={attachmentRef}>
                <ul>
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_UPLOADXML") && <li onClick={onUploadXMLFileClick}>Upload XML</li>}
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_UPLOADCONFIG") && <li onClick={onUploadConfigFileClick}>Upload Config File</li>}
                </ul>
            </PopOver>
            <PopOver ref={downloadAttachmentRef}>
                <ul>
                    <li onClick={onReportAllClick}>Report All</li>
                    <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                    <li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_DOWNLOADXML") &&
                        <li onClick={onDownloadXMLFileClick}>Download XML</li>
                    }
                    {showUI(userPrivileges, "SIETAP_SYSTEMS_DOWNLOADCONFIG") &&
                        <li onClick={onDownloadConfigFileClick}>Download Config File</li>
                    }
                </ul>
            </PopOver>
            {showEditModal && (
                <EditSystem
                    showModal={showEditModal}
                    setShowModal={setShowEditModal}
                    selectedRows={selectedRows}
                    onEditSystemClick={(data) => onEditSystemClick(data)}
                />
            )}

            {showEquipmentGroupModal && (
                <EquipmentGroupList
                    showModal={showEquipmentGroupModal}
                    setShowModal={setShowEquipmentGroupModal}
                    equipmentGroups={equipmentGroups}
                    selectedRows={selectedRows}
                />
            )}

            {isUnlinkedEquipmentGroup && (
                <UnlinkedEquipmentGroups
                    showModal={showUnlinkedEquipmentGroup}
                    setShowModal={setShowUnlinkedEquipmentGroup}
                    selectedRows={selectedRows}
                    defaultSiteId={defaultSiteId}
                    defaultSiteName={defaultSiteName}
                />
            )}

            {isSystemEquipmentGroup && (
                <SystemEquipmentGroupList
                    showModal={showSystemEquipmentGroups}
                    setShowModal={setShowSystemEquipmentGroups}
                    selectedRows={selectedRows}
                />
            )}

            <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} okOnClick={onOkClick} message={message} />
            <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={OnConfirmClick} cancelAction={onCancelClick} message={message} />

            {showReportModal ? (
                <SystemsReport showModal={showReportModal}
                    setShowModal={setShowReportModal}
                    reportFlag={reportFlag}
                    selectedRows={selectedRows}
                    sortByColumns={tableColumns}
                    searchKeyword={keyWord} />
            ) : null}
        </div>
    );
};

export default Systems;
