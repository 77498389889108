import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { useForm, Controller } from "react-hook-form";
//primereact
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from "primereact/inputswitch";
import { Button } from 'primereact/button';
import { ScrollPanel } from "primereact/scrollpanel";
import { Checkbox } from "primereact/checkbox";
//components
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//redux
import { getPasswordPolicyData, onUpdatePasswordPolicyData } from "./passwordPolicySlice";
import { BsExclamationCircleFill } from "react-icons/bs";
import { classNames } from "primereact/utils";
interface Props {}
export const PasswordPolicy: React.FC<Props> = () => {

  type FormData = {
    length: number;
    isCaseSensitive: string;
    isUpperCharIsMust:string;
    isLowerCharIsMust:string;
    isSpecialCharIsMust:string;
    isADigitIsMust:string;
    expiresIn:number;
    accountLockPeriod:number;
    atMostDaysOfDelete:number;
    atMostDaysOfInactive:number;
    atMostFaildAttempts:number;
    attemptsTimeSpanInMinutes:number;
    resetIntervalTimeInHour:number;
    repeatIntervalCount:number;
  };
  const passwordPolicyData = useAppSelector((state) => state.passwordPolicy.passwordPolicyData);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [enablePasswordExpiry, setEnablePasswordExpiry] = useState(false);
  const [autoDisableInactiveAccount, setAutoDisableInactiveAccount] = useState(false);
  const [autoDeleteInactiveAccount, setAutoDeleteInactiveAccount] = useState(false);
  const [caseSensitive, setCaseSensitive] = useState(false);
  
  const passwordPolicyForm: any = useRef();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      length: undefined,
      isCaseSensitive: "",
      isUpperCharIsMust:"",
      isLowerCharIsMust:"",
      isSpecialCharIsMust:"",
      isADigitIsMust:"",
      expiresIn:undefined,
      atMostDaysOfInactive:undefined,
      accountLockPeriod:undefined,
      atMostDaysOfDelete:undefined,
      atMostFaildAttempts:undefined,
      attemptsTimeSpanInMinutes:undefined,
      resetIntervalTimeInHour:undefined,
      repeatIntervalCount:undefined,
    },
  });

  const dispatch=useDispatch<any>()

  useEffect(()=>{
    dispatch(getPasswordPolicyData());
  },[])

  const formSubmitHandler = (data: any) => {
    passwordPolicyForm.current.requestSubmit();
  };

  const onPasswordPolicyFormSubmit = (data: any) => {
    let body={
      length:data.length,
      isCaseSensitive:caseSensitive,
      isUpperCharIsMust:caseSensitive===false?false:data.isUpperCharIsMust,
      isLowerCharIsMust:caseSensitive===false?false:data.isLowerCharIsMust,
      isSpecialCharIsMust:data.isSpecialCharIsMust,
      isADigitIsMust:data.isADigitIsMust,
      isNeverExpires:!enablePasswordExpiry,
      expiresIn:data.expiresIn,
      repeatIntervalCount:data.repeatIntervalCount,
      resetIntervalTimeInHour:data.resetIntervalTimeInHour,
      atMostFaildAttempts:data.atMostFaildAttempts,
      attemptsTimeSpanInMinutes:data.attemptsTimeSpanInMinutes,
      accountLockPeriod:data.accountLockPeriod,
      atMostDaysOfInactive:data.atMostDaysOfInactive,
      atMostDaysOfDelete:data.atMostDaysOfDelete,
      isAutoDisableOnInactive:autoDisableInactiveAccount,
      isNeverDelete:!autoDeleteInactiveAccount
    }
    dispatch(onUpdatePasswordPolicyData(body,setMessage,setDisplayInfoModal))
  };

  const onError = (errors: any, e: any) => {};
  
  useEffect(()=>{
    if(passwordPolicyData)
    { 
      setValue("length",passwordPolicyData?.length);
      setCaseSensitive(passwordPolicyData?.isCaseSensitive)
      setValue("isCaseSensitive",passwordPolicyData?.isCaseSensitive);
      setValue("isUpperCharIsMust",passwordPolicyData?.isUpperCharIsMust);
      setValue("isLowerCharIsMust",passwordPolicyData?.isLowerCharIsMust);
      setValue("isSpecialCharIsMust",passwordPolicyData?.isSpecialCharIsMust);
      setValue("isADigitIsMust",passwordPolicyData?.isADigitIsMust); 
      if(passwordPolicyData?.isNeverExpires === true){
        setEnablePasswordExpiry(false);
      } else {
        setEnablePasswordExpiry(true);
      }
      setValue("expiresIn",passwordPolicyData?.expiresIn);
      setAutoDisableInactiveAccount(passwordPolicyData?.isAutoDisableOnInactive)   
      setValue("atMostDaysOfInactive",passwordPolicyData?.atMostDaysOfInactive);
      if(passwordPolicyData?.isNeverDelete === true)
      setAutoDeleteInactiveAccount(false);
      else{
        setAutoDeleteInactiveAccount(true);
      }
      setValue("atMostDaysOfDelete",passwordPolicyData?.atMostDaysOfDelete);         
      setValue("resetIntervalTimeInHour",passwordPolicyData?.resetIntervalTimeInHour);
      setValue("repeatIntervalCount",passwordPolicyData?.repeatIntervalCount);
      setValue("atMostFaildAttempts",passwordPolicyData?.atMostFaildAttempts);
      setValue("attemptsTimeSpanInMinutes",passwordPolicyData?.attemptsTimeSpanInMinutes); 
      setValue("accountLockPeriod",passwordPolicyData?.accountLockPeriod);
    }

  },[passwordPolicyData])

  const maxLengthValidation = (value, name) => {
    switch (name) {
      case "length":
        if (value < 8 || value > 30)
          return "Minimum length of password should be between 8 and 30";
        break;
      case "resetIntervalTimeInHour":
        if (value < 1 || value > 100)
          return "Minimum time between password changes should be between 1 and 100 hours";
        break;
      case "repeatIntervalCount":
        if (value < 1 || value > 20)
          return "Number of previous passwords that cannot be reused should be between 1 and 20";
        break;
      case "atMostFaildAttempts":
        if (value < 2 || value > 20)
          return "'Maximum number of consecutive failed attempts before the account gets locked should be between 2 and 10";
        break;
      case "attemptsTimeSpanInMinutes":
        if (value < 1 || value > 120)
          return "Time interval for counting failed attempts to lock the account should be between 1 and 120";
        break;
      case "accountLockPeriod":
        if (value < 2 || value > 5000)
          return "Account locked out period' should be between 1 and 5000 minutes";
        break;
      case "expiresIn":
        if (value < 1 || value > 365)
          return "Number of Days for Password Expiry' should be between 1 and 365";
        break;
      case "atMostDaysOfInactive":
        if (value < 1 || value > 365)
          return "Lock inactive account' should be between 1 and 365";
        break;
      case "atMostDaysOfDelete":
        if (value < 1 || value > 365)
          return "Delete inactive account should be between 1 and 365";
        break;
      default:

        break
    }
  }

const getFormErrorMessage = (name) => {
  return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
};

  return (
    <ScrollPanel style={{ width: "100%", height: "100%" }}>
    <div className="main-passwordpolicy">
      <p className="Pagetitle mb-0">
        <b>Password Policy</b>
      </p>
      <form
          onSubmit={handleSubmit(onPasswordPolicyFormSubmit, onError)}
          ref={passwordPolicyForm}>
      <div className="row">
        <div className="col-6">
          <p className="subtitle-passwordpolicy mb-0">Password Requirements</p>
          <hr></hr>
          <div className="password-requirements">
            <div className="password-length pt-2 pl-2">
              Password Length
              <span className="ml-2 position-relative">
                <Controller
                  name="length"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    validate: (value) => maxLengthValidation(value,"length")
                  }}
                  render={({ field, fieldState }) => (
                    <InputNumber id="text" className={classNames('w-100 error-tooltip', { error: fieldState.invalid })} {...field} value={field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false}/>
                  )}
                />
                 {getFormErrorMessage('length')}
              </span>
            </div>
            <div className="checklist pt-2">
              <div className="field-checkbox px-2">
                <Controller
                  name="isCaseSensitive"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      inputId="binary"
                      onChange={(e)=>setCaseSensitive(e.checked)}
                      checked={caseSensitive}
                      // {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="binary">
                  Case sensitive
                </label>
              </div>
              <div className="field-checkbox px-2 pt-3">
                <Controller
                  name="isUpperCharIsMust"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      inputId="binary"
                      checked={field.value}
                      {...field}
                      disabled={caseSensitive===true?false:true}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="binary">
                  Requires at least one upper case character
                </label>
              </div>
              <div className="field-checkbox px-2 pt-3">
                <Controller
                  name="isLowerCharIsMust"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      inputId="binary"
                      checked={field.value}
                      {...field}
                      disabled={caseSensitive===true?false:true}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="binary">
                  Requires at least one lower case character
                </label>
              </div>
              <div className="field-checkbox px-2 pt-3">
                <Controller
                  name="isSpecialCharIsMust"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      inputId="binary"
                      checked={field.value}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="binary">
                  Requires at least one non-alphanumeric character
                </label>
              </div>
              <div className="field-checkbox px-2 pt-3">
                <Controller
                  name="isADigitIsMust"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      inputId="binary"
                      checked={field.value}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="binary">
                  Requires at least one numeric character
                </label>
              </div>
            </div>
          </div>
          <div className="password-expiry">
            <p className="subtitle-passwordpolicy mb-0 pt-3 pb-1">
              Password Expiry
            </p>
            <hr></hr>
            <div className="password-expiry-enable d-flex align-items-center pt-2">
              Enable
              <span className="ml-2 d-flex">
                <InputSwitch
                  checked={enablePasswordExpiry}
                  onChange={(e) => setEnablePasswordExpiry(e.value)}
                />
              </span>
            </div>
            <div className="expirypassword pt-2">
              Expire password after
              <span className="ml-2 mr-2 position-relative">
                <Controller
                  name="expiresIn"
                  control={control}
                  rules={enablePasswordExpiry?{
                    required: "This field is required.",
                    validate: (value) => maxLengthValidation(value,"expiresIn")
                  }:{
                    required: false,
                    validate:undefined
                 }}
                  render={({ field, fieldState }) => (
                    <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={!enablePasswordExpiry?0:field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false} disabled={!enablePasswordExpiry} />
                  )}
                />
                {getFormErrorMessage('expiresIn')}
              </span>
              Days
            </div>
          </div>
          <div className="lock-account">
            <p className="subtitle-passwordpolicy mb-0 pt-3 pb-1">
              Lock Inactive Accounts
            </p>
            <hr></hr>
            <div className="lock-account-enable d-flex align-items-center pt-2">
              Auto Disable Inactive Account
              <span className="ml-2 d-flex">
                <InputSwitch
                  checked={autoDisableInactiveAccount}
                  onChange={(e) => setAutoDisableInactiveAccount(e.value)}
                />
              </span>
            </div>
            <div className="lock-account-inactive pt-2">
              Lock Inactive Accounts after
              <span className="ml-2 mr-2 position-relative">
                <Controller
                  name="atMostDaysOfInactive"
                  control={control}
                  rules={autoDisableInactiveAccount?{
                    required: "This field is required.",
                    validate: (value) => maxLengthValidation(value,"atMostDaysOfInactive")
                  }:{
                     required: false,
                     validate:undefined
                  }}
                  render={({ field, fieldState }) => (
                    <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={!autoDisableInactiveAccount?0:field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false} disabled={!autoDisableInactiveAccount}/>
                  )}
                />
                {getFormErrorMessage('atMostDaysOfInactive')}
              </span>
              days since last login
            </div>
          </div>
          <div className="delete-inactive">
            <p className="subtitle-passwordpolicy mb-0 pt-3 pb-1">
              Delete Inactive Accounts
            </p>
            <hr></hr>
            <div className="delete-inactive-enable d-flex align-items-center pt-2">
              Auto Delete Inactive Account
              <span className="ml-2 d-flex">
             
                <InputSwitch
                  checked={autoDisableInactiveAccount===false?false:autoDeleteInactiveAccount}
                  onChange={(e) => setAutoDeleteInactiveAccount(e.value)}
                  disabled={!autoDisableInactiveAccount}
                />
                
              </span>
            </div>
            <div className="delete-inactive pt-2">
              Delete Inactive Accounts after
              <span className="ml-2 mr-2 position-relative">
                <Controller
                  name="atMostDaysOfDelete"
                  control={control}
                  rules={autoDeleteInactiveAccount?{
                    required: "This field is required.",
                    validate: (value) => maxLengthValidation(value,"atMostDaysOfDelete")
                  }:{
                    required: false,
                    validate:undefined
                 }}
                  render={({ field, fieldState }) => (
                    <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={!autoDeleteInactiveAccount?0:field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false} disabled={autoDisableInactiveAccount===false?true:!autoDeleteInactiveAccount}/>
                  )}
                />
                {getFormErrorMessage('atMostDaysOfDelete')}
              </span>
              days since last login
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="passwordpolicy-checklist pt-4">
            <div className="account-checklist">

              <div className="account-checklist d-flex pt-1">
                <div className="check-list">Minimum time between password changes</div>
                <div className="dot">:</div>
                <span className="ml-2 mr-2 position-relative">
                  <Controller
                    name="resetIntervalTimeInHour"
                    control={control}
                    rules={{
                      required: "This field is required.",
                      validate: (value) => maxLengthValidation(value,"resetIntervalTimeInHour")
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false}/>
                    )}
                  />
                    {getFormErrorMessage('resetIntervalTimeInHour')}
                </span>
                <label htmlFor="inputtext">Hours</label>
              </div>

               <div className="account-checklist d-flex pt-2">
                <div className="check-list">Number of previous passwords that cannot be reused</div>
                <div className="dot">:</div>
                <span className="ml-2 mr-2 position-relative">
                  <Controller
                    name="repeatIntervalCount"
                    control={control}
                    rules={{
                      required: "This field is required.",
                      validate: (value) => maxLengthValidation(value,"repeatIntervalCount")
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false}/>
                    )}
                  />
                  {getFormErrorMessage('repeatIntervalCount')}
                  
                </span>
                <label htmlFor="inputtext">(Maximum 20)</label>
              </div>

              <div className="account-checklist d-flex pt-2">
                <div className="check-list">Maximum number of consecutive failed attempts before the account gets locked</div>
                <div className="dot">:</div>
                <span className="ml-2 mr-2 position-relative">
                  <Controller
                    name="atMostFaildAttempts"
                    control={control}
                    rules={{
                      required: "This field is required.",
                      validate: (value) => maxLengthValidation(value,"atMostFaildAttempts")
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber id="text"  className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false}/>
                    )}
                  />
                  {getFormErrorMessage('atMostFaildAttempts')}
                </span>
                <label htmlFor="inputtext"></label>
              </div>


              <div className="account-checklist d-flex pt-2">
                <div className="check-list">Time interval for counting failed attempts to lock the account</div>
                <div className="dot">:</div>
                <span className="ml-2 mr-2 position-relative">
                  <Controller
                    name="attemptsTimeSpanInMinutes"
                    control={control}
                    rules={{
                      required: "This field is required.",
                      validate: (value) => maxLengthValidation(value,"attemptsTimeSpanInMinutes")
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false}/>
                    )}
                  />
                  {getFormErrorMessage('attemptsTimeSpanInMinutes')}
                </span>
                <label htmlFor="inputtext">(Minutes)</label>
              </div>

              <div className="account-checklist d-flex pt-2">
                <div className="check-list">Account locked out period</div>
                <div className="dot">:</div>
                <span className="ml-2 mr-2 position-relative">
                  <Controller
                    name="accountLockPeriod"
                    control={control}
                     rules={{
                      required: "This field is required.",
                      validate: (value) => maxLengthValidation(value,"accountLockPeriod")
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber id="text" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} value={field.value} onChange={(e) => field.onChange(e.value)} useGrouping={false}/>
                    )}
                  />
                   {getFormErrorMessage('accountLockPeriod')}
                </span>
                <label htmlFor="inputtext">(Minutes)</label>
              </div>

            </div>
          </div>
          <div className="pt-5">
                <Button label= "Save Changes" className="save-btn" onClick={formSubmitHandler} />
            </div>
        </div>
      </div>
      </form>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </div>
    </ScrollPanel>
  );
};
export default PasswordPolicy;
