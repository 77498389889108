import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface feedbackState {
  errors: any;
  notificationMessage: any;
}

let initialState: feedbackState = {
  errors: "",
  notificationMessage: {}
};

const notificationMessageSlice = createSlice({
  name: "notificationmessage",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setNotificationMessageData: (state, { payload }: PayloadAction<any>) => {
      state.notificationMessage = payload;
    }
  },
});

export const getNotificationMessage = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setNotificationMessageData([]));
    const res = await zlink.get(`${baseURL}api/v1/notificationmessage/data`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setNotificationMessageData(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
      if (res.message) {
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const sendNotifcationMessage = (body: any, setDisplayInfoModal,setMessage,setShowModal): AppThunk => async (dispatch) => {
  try {
    dispatch(setNotificationMessageData([]));
    const res = await zlink.post(`${baseURL}api/v1/notificationmessage/save`, body);
    if (res.status === "done") { 
      setShowModal(false)
      setMessage("Notification message updated");
      setDisplayInfoModal(true);  
    } else {
      setMessage(" Failed to update notification message");
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setNotificationMessageData
} = notificationMessageSlice.actions;

export default notificationMessageSlice.reducer;